import { PortableText } from "@portabletext/react"
import React from "react"
import { getImageUrl } from "../../utils/getImageUrl"

const ImageWithLink = ({ value }) => {
  const { link, image } = value
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <img src={getImageUrl(image.asset._ref)} alt="" />
    </a>
  )
}

const components = {
  types: {
    imageWithLink: ({ value }) => <ImageWithLink value={value} />,
  },
}

const ContentContactPage = ({ content }) => {
  return (
    <div className="w-full content p-4 pt-9">
      {content && content[0] && (
        <PortableText value={content} components={components} />
      )}
    </div>
  )
}

export default ContentContactPage
